import React from 'react'
import { graphql } from 'gatsby'
import CategoryTemplateDetails from '../components/CategoryTemplateDetails'
import MainContent from '../components/MainContent'
import Page from '../components/Page'

class CategoryTemplate extends React.Component {
  render() {
    return (
      <Page {...this.props}>
        <MainContent forTiles>
          <CategoryTemplateDetails {...this.props} />
        </MainContent>
      </Page>
    )
  }
}

export default CategoryTemplate

export const pageQuery = graphql`
  query CategoryPage($category: String) {
    ...siteQuery
    allMarkdownRemark(
      limit: 1000
      filter: {
        frontmatter: {
          category: { eq: $category }
          layout: { eq: "post" }
          draft: { ne: true }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            date
            category
            description
          }
        }
      }
    }
  }
`
